import React, {RefObject} from "react";
import {
    Drawer,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Select,
    Switch,
    Table,
    Tooltip,
    Typography
} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import IActionScript from "../../../../../model/interface/dataStorage/IActionScript";
import Utils from "../../../../../utils";
import ScriptPicker from "../../../../shared/pickers/ScriptPicker";
import Button from "../../../../shared/button/Button";
import Modal from "../../../../shared/modal/Modal";
import {ColumnsType} from "antd/es/table";
import {connect} from "react-redux";
import {IAppState} from "../../../../../redux/store";
import IScript from "../../../../../model/interface/dataStorage/IScript";


interface IScriptActionArgument {
    name: string,
    type: string,
    value: any
}

interface IProps {
    resource: IAction | null,
    onSave: (action: IAction, close: boolean) => void,
    onCancel: () => void,
    scripts: IScript[]
}

interface IState {
    refForm: RefObject<FormInstance>,
    actionScript: IActionScript | null,
    argument: IScriptActionArgument,
    type: string
}

const newArgument = {
    name: '',
    type: 'string',
    value: null
}

class ContentTypeActionArguments extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context)
        this.state = {
            refForm: React.createRef() as RefObject<FormInstance>,
            actionScript: null,
            argument: {...newArgument},
            type: newArgument.type
        }
    }

    buildArgumentTypeOptions = () => {
        return [
            {
                label: 'Text',
                value: 'string'
            },
            {
                label: 'Číslo',
                value: 'number'
            },
            {
                label: 'Logická hodnota',
                value: 'boolean'
            }
        ]
    }

    updateResource = (actionScripts: IActionScript[]) => {
        const action = this.props.resource as IAction
        this.props.onSave({
            ...action,
            actionScripts
        }, false)
    }

    actionScriptAdd = () => {
        this.updateResource([...this.props.resource?.actionScripts || [],
            {
                uuid: Utils.uuid(),
                arguments: {},
                weight: 1,
            }
        ])
    }

    actionScriptRemove = (actionScript: IActionScript) => {
        this.updateResource(Utils.removeFromArrayWhere(
            this.props.resource?.actionScripts || [],
            {uuid: actionScript.uuid}
        ))
    }

    actionScriptUpdate = (actionScript: IActionScript, values: {[name: string]: any}) => {
        const {scripts, resource} = this.props
        let requiredArguments: {[name: string]: any} = {}
        if (values.hasOwnProperty('script')){
            const script = scripts.find(s => s.uuid === values.script)
            script?.requiredArguments?.forEach(s => {
                requiredArguments[s] = undefined
            })
            console.log(script)
        }



        this.updateResource(Utils.arrayAddOrUpdateWhere(
            resource?.actionScripts || [],
            {uuid: actionScript.uuid},
            {
                ...actionScript,
                ...values,
                arguments: {...requiredArguments, ...values.arguments}
            }
        ))
    }

    argumentAdd = (actionScript: IActionScript) => {
        this.setState({actionScript, argument: newArgument, type: newArgument.type})
        setTimeout(() => this.state.refForm.current?.resetFields(), 1)
    }

    argumentUpdate = (values: any) => {
        const actionScript = this.state.actionScript! as IActionScript
        let _arguments = Array.isArray(actionScript.arguments) && !actionScript.arguments.length ? {} : actionScript.arguments
        if (this.state.argument.name !== values.name) {
            delete _arguments[this.state.argument.name]
        }
        _arguments[values.name] = this.argumentParseValue(values.type, values)
        this.updateResource(Utils.arrayAddOrUpdateWhere(
            this.props.resource?.actionScripts,
            {uuid: actionScript.uuid},
            {
                ...actionScript,
                arguments: _arguments
            }
        ))
        this.argumentCancel()
    }

    argumentValuesChange = (values: any) => {
        if (values.type) {
            this.setState({type: values.type})
        }
    }

    argumentCancel = () => {
        this.setState({actionScript: null})
    }

    argumentEdit = (actionScript: IActionScript, name: string) => {
        const value = actionScript.arguments[name]
        const type = typeof value
        let argument = {name, type} as any
        switch (type) {
            case('boolean'):
                argument.booleanValue = !!value;
                break;
            case('number'):
                argument.numberValue = parseInt(value, 10);
                break;
            case('string'):
                argument.stringValue = value + "";
                break;
        }
        this.setState({actionScript, argument, type: argument.type === 'undefined' ? undefined : argument.type })
        setTimeout(() => this.state.refForm.current?.resetFields(), 1)
    }

    argumentDelete = (actionScript: IActionScript, name: string) => {
        const actionScripts = this.props.resource?.actionScripts!
        const index = Utils.findIndex(actionScripts, {uuid: actionScript.uuid})
        delete actionScripts[index].arguments[name]
        this.updateResource(actionScripts)
    }

    argumentParseValue(type: string, values: any): any {
        switch (type) {
            case('number'):
                return parseInt(values.numberValue, 10)
            case('boolean'):
                return values.booleanValue;
            case('string'):
            default:
                return values.stringValue
        }
    }

    buildData = () => {
        const action = this.props.resource as IAction
        return action && action.actionScripts ? [...action.actionScripts] : [];
    }

    buildColumns = (): ColumnsType<IActionScript> => {
        const {scripts} = this.props
        return [
            {
                title: 'Skript',
                render: (_, actionScript) =>
                    <ScriptPicker value={actionScript.script} autoFocus={true}
                                  onChange={uuid => this.actionScriptUpdate(actionScript, {script: uuid})}/>,
                width: '45%'
            },
            {
                title: 'Váha',
                render: (_, actionScript) =>
                    <InputNumber style={{width: 40}} defaultValue={actionScript.weight || 1}
                                 onChange={(weight) => this.actionScriptUpdate(actionScript, {weight})}/>,
                width: 80
            },
            {
                title: 'Argumenty',
                render: (_, actionScript) => {
                    const script = scripts.find(s => s.uuid === actionScript.script)
                    return <>
                        {Object.keys(actionScript.arguments).map(name => (
                            <Row key={name} align={"middle"}>
                                <Typography.Text className={'mr-2'} strong={true}>
                                    {name}:
                                </Typography.Text> {actionScript.arguments[name]}
                                <Button type={"link"} size={"small"} icon={<EditOutlined/>} className={'ml-1'}
                                        onClick={() => this.argumentEdit(actionScript, name)}/>
                                <Popconfirm title="Opravdu odebrat?"
                                            onConfirm={() => () => this.argumentDelete(actionScript, name)}
                                            okText="Ano" cancelText="Ne">
                                    <Button disabled={script?.requiredArguments?.includes(name)} type={"link"} danger
                                            size={"small"} icon={<DeleteOutlined/>}/>
                                </Popconfirm>
                            </Row>
                        ))}
                        <Button type={"link"} size={"small"} icon={<PlusOutlined/>}
                                onClick={() => this.argumentAdd(actionScript)}/>
                    </>
                }
            },
            {
                title: <Row justify={"end"}>
                    <Button icon={<PlusOutlined/>} onClick={() => this.actionScriptAdd()} type={"primary"}
                            size={"small"}>Přidat</Button>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_: any, actionScript: IActionScript) => {
                    return (
                        <div className="text-right d-flex justify-content-end">
                            <Tooltip title={"Odstranit"}>
                                <Button onClick={() => this.actionScriptRemove(actionScript)} type={'danger'}
                                        icon={<DeleteOutlined/>} size="small"/>
                            </Tooltip>
                        </div>
                    )
                },
                width: 120
            }
        ]
    }

    render() {
        const {resource, onCancel} = this.props
        const {refForm, argument, actionScript, type} = this.state
        return (
            <Modal title={resource ? resource.label : 'Nová akce'} visible={!!resource} onCancel={onCancel}
                   fullScreenOption={true} onOk={onCancel} className={'overflow-hidden'} width={1000}
                   bodyStyle={{maxHeight: 'calc(100vh - 315px)', overflow: "auto", padding: 0}}>
                <Drawer
                    title="Správa argumentů"
                    placement="right"
                    width={'40%'}
                    closable={true}
                    onClose={this.argumentCancel}
                    visible={actionScript !== null}
                    getContainer={false}
                    style={{position: 'absolute'}}
                >
                    <Form size={"small"} ref={refForm} initialValues={{...argument}}
                          onFinish={(values) => this.argumentUpdate(values)}
                          onValuesChange={(values) => this.argumentValuesChange(values)}>
                        <Form.Item
                            label={"Název"}
                            name={"name"}
                            rules={[{required: true, message: 'Tato položka je povinná.'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={"Type"}
                            name={"type"}
                            rules={[{required: true, message: 'Tato položka je povinná.'}]}
                        >
                            <Select options={this.buildArgumentTypeOptions()}/>
                        </Form.Item>
                        {type === 'string' && (
                            <Form.Item
                                label={"Hodnota"}
                                name={"stringValue"}
                                rules={[{required: true, message: 'Tato položka je povinná.'}]}
                            >
                                <Input/>
                            </Form.Item>
                        )}
                        {type === 'boolean' && (
                            <Form.Item
                                label={"Hodnota"}
                                name={"booleanValue"}
                                valuePropName={'checked'}
                            >
                                <Switch size={"default"}/>
                            </Form.Item>
                        )}
                        {type === 'number' && (
                            <Form.Item
                                label={"Hodnota"}
                                name={"numberValue"}
                                rules={[{required: true, message: 'Tato položka je povinná.'}]}
                            >
                                <InputNumber/>
                            </Form.Item>
                        )}
                        <Button htmlType={"submit"} type={"primary"}>Uložit</Button>
                    </Form>
                </Drawer>
                <Table
                    className={'p-4'}
                    rowKey={row => row.uuid}
                    sticky={true}
                    pagination={false}
                    dataSource={this.buildData()}
                    columns={this.buildColumns()}
                />
            </Modal>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    const {scripts} = state.setup

    return {
        scripts
    }
}

export default connect(mapStateToProps)(ContentTypeActionArguments)